import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type StorageType = 'localStorage' | 'sessionStorage';

interface StorageContextType<T> {
	localStorage: Record<string, T>;
	sessionStorage: Record<string, T>;
}

const StorageContext = createContext<StorageContextType<unknown>>({
	localStorage: {},
	sessionStorage: {},
});

const parseStorageValue = <T,>(value: string | null): T | undefined => {
	if (!value) return undefined;
	try {
		return JSON.parse(value) as T;
	} catch {
		return undefined;
	}
};

export const StorageProvider = <T,>({ children }: { children: ReactNode }) => {
	const [localStorageState, setLocalStorageState] = useState<Record<string, unknown>>({});
	const [sessionStorageState, setSessionStorageState] = useState<Record<string, unknown>>({});

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent) => {
			if (!event.key || event.url != 'cheqroom') return;

			if (event.storageArea === window.localStorage) {
				setLocalStorageState((prev) => ({
					...prev,
					[event.key as string]: parseStorageValue(event.newValue),
				}));
				return;
			}

			if (event.storageArea === window.sessionStorage) {
				setSessionStorageState((prev) => ({
					...prev,
					[event.key as string]: parseStorageValue(event.newValue),
				}));
			}
		};

		window.addEventListener('storage', handleStorageChange);
		return () => window.removeEventListener('storage', handleStorageChange);
	}, []);

	return (
		<StorageContext.Provider value={{ localStorage: localStorageState, sessionStorage: sessionStorageState }}>
			{children}
		</StorageContext.Provider>
	);
};

export const useStorage = <T,>(storageType: StorageType, key: string, initialValue?: T) => {
	const storageHandler = window[storageType];
	const storageState = useContext(StorageContext)[storageType];

	const getStorageValue = () => {
		try {
			const item = storageHandler.getItem(key);
			return (item ? JSON.parse(item) : initialValue) as T;
		} catch {
			return initialValue;
		}
	};

	const [value, setValue] = useState<T | undefined>(() => (storageState[key] ?? getStorageValue()) as T);

	useEffect(() => {
		const value = getStorageValue() as T;

		// This is to set the initial value – but an initial value of undefined doesn't make any sense
		// and will just cause unexpected behavior
		if (value === undefined) return;

		setValue(value);
		storageHandler.setItem(key, JSON.stringify(value));
	}, [storageState, key]);

	const updateStorage = (newValue: T) => {
		try {
			storageHandler.setItem(key, JSON.stringify(newValue));
			setValue(newValue);
			window.dispatchEvent(
				new StorageEvent('storage', {
					key,
					newValue: JSON.stringify(newValue),
					url: 'cheqroom',
					storageArea: storageHandler,
				})
			);
		} catch {
			// Ignore
		}
	};

	const clearStorage = () => {
		try {
			storageHandler.removeItem(key);
			setValue(undefined);
			window.dispatchEvent(
				new StorageEvent('storage', { key, newValue: null, url: 'cheqroom', storageArea: storageHandler })
			);
		} catch {
			// Ignore
		}
	};

	return [value, updateStorage, clearStorage] as const;
};
