import { useLocalStorage } from '@cheqroom/hooks';

import { useAuth } from './../../context/Authentication.context';
import { OverviewConfig, OverviewViewConfig } from './types';

const useOverviewConfig = <Field>({
	view,
	type,
	defaultConfig,
}: {
	view?: 'table' | 'cards' | 'scheduler';
	type: 'items-list' | 'check-outs-list' | 'contacts-list' | 'kits-list' | 'reservations-list' | 'spotchecks-list';
	defaultConfig?: OverviewViewConfig<Field>;
}) => {
	const { id: userId } = useAuth();

	const [overviewConfig, setOverviewConfig, clearOverviewConfig] = useLocalStorage<OverviewConfig>(
		`${userId!}app.${type}.config`,
		{ view: 'table', pageSize: 25 }
	);
	const [overviewViewConfig, setOverviewViewConfig, clearOverviewViewConfig] = useLocalStorage<
		OverviewViewConfig<Field>
	>(
		`${userId!}app.${type}.${view ?? overviewConfig!.view}`,
		defaultConfig
			? {
					fields: defaultConfig.fields,
					inlineFields: defaultConfig.inlineFields,
				}
			: undefined
	);

	const handleSetOverviewConfig = (config: OverviewConfig & OverviewViewConfig<Field>) => {
		setOverviewConfig({
			view: config.view,
			pageSize: config.pageSize,
		});
		setOverviewViewConfig({
			fields: config.fields,
			inlineFields: config.inlineFields,
		});
	};

	const handleClearOverviewConfig = () => {
		clearOverviewConfig();
		clearOverviewViewConfig();
	};

	return [
		{
			...overviewConfig!,
			...overviewViewConfig!,
		},
		handleSetOverviewConfig,
		handleClearOverviewConfig,
	] as const;
};

export default useOverviewConfig;
