import 'twin.macro';

import { Card, Image, Stack, Text } from '@cheqroom/ui';
import { FC } from 'react';

import itemPreviewLogo from '../../../public/assets/images/item-preview.png';
import { getIconForField } from '../../hooks/item-fields/helper';
import { CustomField, SystemField, SystemFieldType } from '../../hooks/item-fields/types';
import { Info } from '../Info/Info';
import getDefaultValueForItemField from './helpers/get-default-value-for-item-field';
import { CardTag } from './ItemPreview.styles';

type Props = {
	viewType: 'table' | 'cards' | 'scheduler';
	fieldIds: string[];
	getFieldById: (fieldId: string) => SystemField<SystemFieldType> | CustomField | undefined;
};

const RowPreview: FC<Omit<Props, 'viewType'>> = ({ fieldIds, getFieldById }) => (
	<Stack vertical tw="w-full">
		<CardTag size="small">Preview</CardTag>
		<Card tw="px-4 py-3 relative">
			<Stack spacing="tight">
				<Image
					// TODO localise this alt text
					alt="item preview"
					fit="contain"
					src={itemPreviewLogo}
					tw="h-[40px] w-[40px]"
				/>
				<Stack vertical>
					<Text weight="bold">GoPro Hero 10 Black</Text>
					<Stack spacing="tight">
						{fieldIds?.map((fieldId) => {
							const field = getFieldById(fieldId);

							return (
								<Info
									key={fieldId}
									icon={getIconForField(fieldId)}
									text={getDefaultValueForItemField(fieldId) ?? field?.name ?? ''}
								/>
							);
						})}
					</Stack>
				</Stack>
			</Stack>
		</Card>
	</Stack>
);

const CardPreview: FC<Omit<Props, 'viewType'>> = ({ fieldIds, getFieldById }) => (
	<Stack vertical tw="w-80">
		<CardTag size="small">Preview</CardTag>
		<Card tw="relative">
			<Image src={itemPreviewLogo} alt="Item preview image" tw="h-52 mt-6" fit="contain" />
			<Card.Footer>
				<Stack vertical spacing="tight">
					<Text weight="bold">GoPro Hero 10 Black</Text>
					<Stack spacing="tight">
						{fieldIds?.map((fieldId) => {
							const field = getFieldById(fieldId);

							return (
								<Info
									key={fieldId}
									icon={getIconForField(fieldId)}
									text={getDefaultValueForItemField(fieldId) ?? field?.name ?? ''}
								/>
							);
						})}
					</Stack>
				</Stack>
			</Card.Footer>
		</Card>
	</Stack>
);

const ItemPreview: FC<Props> = ({ viewType, ...props }) => {
	switch (viewType) {
		case 'scheduler':
		case 'table':
			return <RowPreview {...props} />;
		case 'cards':
			return <CardPreview {...props} />;
		default:
			return null;
	}
};

export default ItemPreview;
