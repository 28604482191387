import { useModalContext } from '@cheqroom/ui/src/components/Modal/Modal.context';
import { ReactElement, useRef } from 'react';
import { uuid } from 'short-uuid';

export interface ModalProps {
	isShown: boolean;
	onClose: () => void;
	setConfirmed?: (confirmed: boolean) => void;
}

type RenderDialogFn<Props> = (outgoingProps: ModalProps & Props) => ReactElement;

export default function useModal<Props = Record<string, unknown>>(
	renderDialog: RenderDialogFn<Props>,
	awaitConfirmation: true
): readonly [(props?: Props) => Promise<boolean | undefined>, () => void];
export default function useModal<Props = Record<string, unknown>>(
	renderDialog: RenderDialogFn<Props>,
	awaitConfirmation?: false
): readonly [(props?: Props) => void, () => void];
export default function useModal<Props = Record<string, unknown>>(
	renderDialog: RenderDialogFn<Props>,
	awaitConfirmation = false
): readonly [(props?: Props) => void | Promise<boolean | undefined>, () => void] {
	const key = useRef(uuid()).current;

	const { hideModal, showModal } = useModalContext();

	const show = async (props: Props = {} as Props) => {
		if (awaitConfirmation) {
			return new Promise<boolean>((resolve) => {
				showModal(key, () => {
					return renderDialog({
						isShown: true,
						onClose: (...args) => {
							hide(...args);
							resolve(false);
						},
						setConfirmed: resolve,
						...props,
					});
				});
			});
		}

		showModal(key, () => {
			return renderDialog({
				isShown: true,
				onClose: hide,
				...props,
			});
		});
	};

	const hide = () => {
		hideModal(key);
	};

	return [show, hide] as const;
}
