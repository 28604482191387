import ko from 'knockout';
import moment from 'moment';

export default {
	mixin: function (reservation, options) {
		var orig_fromJson = reservation._fromJson,
			orig_reset = reservation.reset,
			orig_canAddItems = reservation.canAddItems,
			orig_canRemoveItems = reservation.canRemoveItems,
			orig_reserve = reservation.reserve,
			orig_reserveAgain = reservation.reserveAgain,
			orig_reserveRepeat = reservation.reserveRepeat,
			orig_makeOrder = reservation.makeOrder,
			orig_undoReserve = reservation.undoReserve,
			orig_cancel = reservation.cancel,
			orig_close = reservation.close,
			orig_undoClose = reservation.undoClose,
			orig_clearLocation = reservation.clearLocation,
			orig_setLocation = reservation.setLocation;

		var global = options.global,
			perm = global.getPermissionHandler();

		// Core method overrides
		// ----
		reservation._fromJson = function (data, options) {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			return orig_fromJson.apply(reservation, [data, options]).then(function () {
				reservation.oFrom(reservation.from);
				reservation.oTo(reservation.to);
				reservation.oLabel(global.central._getLabelById('reservationLabels', reservation.label));

				return data;
			});
		};

		reservation.reserve = function () {
			return orig_reserve.apply(reservation).then(
				function (resp) {
					reservation._triggerAction(':changed');

					reservation.oNumber(resp.number);

					return resp;
				},
				function (err) {
					if (err && err.code == 422) {
						if (
							err.opt &&
							err.opt.detail.indexOf('reservation has no fromDate set') != -1 &&
							reservation.oFrom() != null
						) {
							var useHours = global.central.profile().useHours;
							var dayMode = false; // we assume this will never by called via fullcalendar.io day selection
							var from = reservation
								._getDateHelper()
								.makeStartDate(
									reservation.oFrom(),
									useHours,
									dayMode,
									reservation.getMinDateFrom(),
									reservation.getMaxDateFrom()
								);

							return reservation.setFromDate(from, true).then(function () {
								return orig_reserve.apply(reservation).then(function (resp) {
									reservation._triggerAction(':changed');

									reservation.oNumber(resp.number);

									return resp;
								});
							});
						}
					}

					return Promise.reject(err);
				}
			);
		};

		//Override CoreJS method
		reservation.canUndoArchive = () => {
			return global.getDataSource('reservations').call(reservation.id, 'canUndoArchive');
		};

		reservation.undoReserve = function () {
			return orig_undoReserve.apply(reservation).then(function () {
				reservation._triggerAction(':changed');
			});
		};

		reservation.makeOrder = function (bookable_items) {
			return orig_makeOrder.apply(reservation, [bookable_items]).then(function (resp) {
				reservation._triggerAction(':changed');

				return resp;
			});
		};

		reservation.reserveAgain = function (params) {
			return orig_reserveAgain.apply(reservation, [params, true]).then(function (resp) {
				reservation._triggerAction(':changed');

				return resp;
			});
		};

		reservation.reserveRepeat = function () {
			return orig_reserveRepeat.apply(reserveRepeat, arguments).then(function (resp) {
				reservation._triggerAction(':changed');

				return resp;
			});
		};

		reservation.cancel = function (message, cancelAll) {
			var dfdCancel;

			if (cancelAll) {
				dfdCancel = reservation.cancelRepeat.apply(reservation, [message, true]);
			} else {
				dfdCancel = orig_cancel.apply(reservation, [message, true]);
			}

			return dfdCancel.then(function (resp) {
				reservation.oStatus('cancelled');

				reservation._getConflicts();

				reservation._triggerAction(':changed');

				return resp;
			});
		};

		reservation.close = function (message, skipThen) {
			return orig_close.apply(reservation, [message]).then(function (resp) {
				if (!skipThen) {
					reservation.oStatus('closed_manually');
					reservation._getConflicts();
					reservation._triggerAction(':changed');
				}

				return resp;
			});
		};

		reservation.undoClose = function () {
			return orig_undoClose.apply(reservation).then(function (resp) {
				reservation.oStatus('open');

				reservation._getConflicts();

				reservation._triggerAction(':changed');

				return resp;
			});
		};

		reservation.reset = async function () {
			//Use apply to call function so we can pass the
			//Base context otherwise it would use Window context
			await orig_reset.apply(reservation);

			//Reset observables to defaults
			reservation.oFrom(reservation.from);
			reservation.oTo(reservation.to);
			reservation.oFromDateError(null);
			reservation.oToDateError(null);
		};

		reservation.onSetFromDate = function () {
			reservation.isBusy(true);

			var from = reservation.oFrom();

			reservation
				.setFromDate(from, true)
				.then(function () {
					reservation._getConflicts();

					reservation._triggerAction(':changed');
				})
				.finally(function () {
					reservation.isBusy(false);
				});
		};

		reservation.onSetToDate = function () {
			reservation.isBusy(true);

			var to = reservation.oTo();

			reservation
				.setToDate(to, true)
				.then(function () {
					reservation._getConflicts();

					reservation._triggerAction(':changed');
				})
				.finally(function () {
					reservation.isBusy(false);
				});
		};

		reservation.onClearToDate = function () {
			reservation.isBusy(true);

			return reservation
				.clearToDate(true)
				.then(function () {
					reservation._getConflicts();

					reservation._triggerAction(':changed');
				})
				.finally(function () {
					reservation.isBusy(false);
				});
		};

		reservation.onClearFromDate = function () {
			reservation.isBusy(true);

			return reservation
				.clearFromDate(true)
				.then(function () {
					reservation._getConflicts();

					reservation._triggerAction(':changed');
				})
				.finally(function () {
					reservation.isBusy(false);
				});
		};

		reservation.clearLocation = () => {
			if (reservation.oFrom()) {
				reservation.onClearFromDate();
				reservation.oFrom(null);
			}
			if (reservation.oTo()) {
				reservation.onClearToDate();
				reservation.oTo(null);
			}
			return orig_clearLocation.apply(reservation);
		};

		reservation.setLocation = (location) => {
			if (reservation.oFrom()) {
				reservation.onClearFromDate();
				reservation.oFrom(null);
			}
			if (reservation.oTo()) {
				reservation.onClearToDate();
				reservation.oTo(null);
			}
			return orig_setLocation.apply(reservation, [location]);
		};

		// Observables
		// ----
		reservation.oFrom = ko.observable(reservation.from).extend({ required: true });
		reservation.oFromDateError = ko.observable(null).extend({ rateLimit: 1 });

		reservation.oTo = ko.observable(reservation.to).extend({ required: true });
		reservation.oToDateError = ko.observable(null).extend({ rateLimit: 1 });

		// Computables
		// ----
		reservation._updateFrom = ko.computed(function () {
			reservation.from = reservation.oFrom();
		});
		reservation._updateDue = ko.computed(function () {
			reservation.to = reservation.oTo();
		});

		reservation.isValidFromDate = ko.computed(function () {
			var from = reservation.oFrom(),
				status = reservation.oStatus();

			return reservation.oFromDateError() === null;
		});
		reservation.isValidToDate = ko.computed(function () {
			var from = reservation.oFrom(),
				to = reservation.oTo(),
				status = reservation.oStatus();

			return reservation.oToDateError() === null;
		});

		reservation.canAddItems = ko.computed(function () {
			var status = reservation.oStatus(),
				isOwn = reservation.isOwn();
			return perm.hasReservationPermission('addItems', { own: isOwn }) && orig_canAddItems.apply(reservation);
		});
		reservation.canRemoveItems = ko.computed(function () {
			var status = reservation.oStatus(),
				isOwn = reservation.isOwn();
			return (
				perm.hasReservationPermission('removeItems', { own: isOwn }) && orig_canRemoveItems.apply(reservation)
			);
		});

		reservation.detailedStatus = ko.computed(function () {
			var from = reservation.oFrom(),
				to = reservation.oTo(),
				status = reservation.oStatus(),
				profile = global.central.profile();

			// Return some fake statuses which are more detailed,
			// so we can build a nice looking friendly message in the dom
			if (from && to && status) {
				if (reservation.archived) {
					return 'archived';
				} else if (reservation.status == 'open') {
					var now = moment();
					const granularity = profile.useHours ? 'minute' : 'day';

					if (now.isBefore(from, granularity)) {
						return 'open';
					} else if (now.isAfter(from, granularity) && now.isAfter(to, granularity)) {
						return 'past';
					} else if (now.isAfter(from, granularity)) {
						return 'overdue';
					}
				} else if (reservation.status == 'cancelled') {
					return 'cancelled';
				} else if (reservation.status == 'closed') {
					return 'closed';
				}
			}

			return '';
		});

		reservation.friendlyDuration = ko.pureComputed(function () {
			var oTo = reservation.oTo();

			if (reservation.from != null && reservation.to != null) {
				return moment.duration(reservation.to - reservation.from).humanize();
			}
			return null;
		});

		//Override visible conflicts
		reservation.oVisibleConflicts = ko.pureComputed(function () {
			var showAllConflicts = reservation.showAllConflicts(),
				conflicts = reservation.oConflicts();
			return reservation.visibleConflicts(conflicts, showAllConflicts);
		});
	},
};
