import {
	CheckoutStatus,
	Item,
	ItemCheckinStatus,
	ItemKind,
	ItemStatus,
	ReservationStatus,
} from '../../../@types/graphql.d';
import { KitStatus } from '../../../@types/python.d';

export enum ItemConflict {
	AVAILABLE = 'AVAILABLE',
	ASSIGNED = 'ASSIGNED',
	CHECKED_OUT = 'CHECKED_OUT',
	CHECKING_OUT = 'CHECKING_OUT',
	RETIRED = 'RETIRED',
	FLAGGED = 'FLAGGED',
	RESERVED = 'RESERVED',
	UNAVAILABLE = 'UNAVAILABLE',
	CANNOT_RESERVE = 'CANNOT_RESERVE',
	CANNOT_CHECKOUT = 'CANNOT_CHECKOUT',
	AT_WRONG_LOCATION = 'AT_WRONG_LOCATION',
	UNAVAILABLE_QUANTITY = 'UNAVAILABLE_QUANTITY',
	INSUFFICIENT_QUANTITY = 'INSUFFICIENT_QUANTITY',
	RESTRICTED = 'RESTRICTED',
	UNAVAILABLE_TO_ADD_TO_KIT = 'UNAVAILABLE_TO_ADD_TO_KIT',
	KIT_IS_IN_CUSTODY = 'KIT_IS_IN_CUSTODY',
}

export type ReservationConflictItem = Pick<Item, 'canBeReserved' | 'canBeCheckedOut' | 'isRestricted'> & {
	availability: Pick<NonNullable<Item['availability']>, 'status' | 'quantity'> | null;
	totalCheckedOutQuantity?: NonNullable<Item['totalCheckedOutQuantity']> | null;
	locationId: NonNullable<Item['locationId']> | null;
	kind: NonNullable<Item['kind']> | null;
	bookedQuantity: NonNullable<Item['bookedQuantity']> | null;
	quantity: NonNullable<Item['quantity']> | null;
};
export type CheckoutConflictItem = Pick<Item, 'canBeCheckedOut' | 'status' | 'isRestricted'> & {
	availability: Pick<NonNullable<Item['availability']>, 'status' | 'quantity'> | null;
	availabilityForCheckout: Pick<NonNullable<Item['availability']>, 'status' | 'quantity'> | null;
	checkoutId: NonNullable<Item['checkoutId']> | null;
	kind: NonNullable<Item['kind']> | null;
	bookedQuantity: NonNullable<Item['bookedQuantity']> | null;
	quantity: NonNullable<Item['quantity']> | null;
	locationId: NonNullable<Item['locationId']> | null;
	bulkInstanceIndex?: number;
	checkinStatus: NonNullable<Item['checkinStatus']> | null;
};
export type AssignToKitConflictItem = Pick<Item, 'kind'> & {
	availability: Pick<NonNullable<Item['availability']>, 'status' | 'quantity'> | null;
};

/**
 * Check if the bulk instance index is available for checkout
 * @param quantityAvailableForCheckout The quantity available for checkout
 * @param instanceIndex The bulk instance index
 */
export const isBulkInstanceAvailable = (quantityAvailableForCheckout: number, instanceIndex: number) => {
	// Construct an array of available bulk instance indices
	const availableBulkInstanceIndices = Array.from({ length: quantityAvailableForCheckout }, (_, i) => i);
	// Check if the instanceIndex is included in the array of available bulk instance indices
	return availableBulkInstanceIndices.includes(instanceIndex);
};

export const getCheckoutItemConflict = (
	item: CheckoutConflictItem,
	context: {
		checkoutLocation: string | null;
		checkoutStatus: CheckoutStatus | null;
		checkoutId: string | null;
		isMaintenanceUser?: boolean;
	}
): ItemConflict => {
	if (!item.canBeCheckedOut) return ItemConflict.CANNOT_CHECKOUT;
	if (item.checkinStatus === ItemCheckinStatus.ALL) return ItemConflict.AVAILABLE;
	if (item.status === ('CHECKING_OUT' as ItemStatus) && item.checkoutId !== context.checkoutId)
		return ItemConflict.CHECKING_OUT;

	const isBulkItem = item.kind === ('BULK' as ItemKind.BULK);

	if (isBulkItem) {
		if (item?.availability?.status === 'RETIRED') return ItemConflict.RETIRED;

		const bookedQuantity = Number(item?.bookedQuantity);
		const availableQuantity = Number(item?.availability?.quantity);

		const quantityAvailableForCheckout = item?.availabilityForCheckout?.quantity
			? Number(item?.availabilityForCheckout?.quantity)
			: undefined;

		if (quantityAvailableForCheckout && bookedQuantity > quantityAvailableForCheckout) {
			const bulkInstanceIsAvailable = isBulkInstanceAvailable(
				quantityAvailableForCheckout,
				item.bulkInstanceIndex || 0
			);

			if (!bulkInstanceIsAvailable) {
				return ItemConflict.UNAVAILABLE;
			}
		}

		if (bookedQuantity > availableQuantity || item?.availability?.status === 'UNAVAILABLE') {
			return ItemConflict.UNAVAILABLE_QUANTITY;
		}
	}

	if (!isBulkItem) {
		if (item.availabilityForCheckout && item.availabilityForCheckout?.status !== 'AVAILABLE') {
			switch (item.availabilityForCheckout?.status) {
				case 'ASSIGNED':
					return ItemConflict.ASSIGNED;
				case 'CHECKED_OUT':
					return ItemConflict.CHECKED_OUT;
				case 'RETIRED':
					return ItemConflict.RETIRED;
			}
		}
		if (item.availability && item.availability.status !== 'AVAILABLE') {
			switch (item.availability.status) {
				case 'FLAGGED':
					return context.isMaintenanceUser ? ItemConflict.AVAILABLE : ItemConflict.FLAGGED;
				case 'RESERVED':
					if (item.isRestricted) return ItemConflict.RESTRICTED;
					return ItemConflict.RESERVED;
				default:
				case 'UNAVAILABLE':
					return ItemConflict.UNAVAILABLE;
			}
		}
	}

	if (item.isRestricted) {
		return ItemConflict.RESTRICTED;
	}

	if (context.checkoutLocation && item.locationId !== context.checkoutLocation) {
		return ItemConflict.AT_WRONG_LOCATION;
	}

	return ItemConflict.AVAILABLE;
};

export const getReservationItemConflict = (
	item: ReservationConflictItem,
	context: {
		reservationLocation?: string | null;
		reservationStatus: ReservationStatus | null;
		isMaintenanceUser?: boolean;
	}
): ItemConflict => {
	if (!item.canBeReserved) return ItemConflict.CANNOT_RESERVE;

	if (context.reservationStatus === ('OPEN' as ReservationStatus) && !item.canBeCheckedOut)
		return ItemConflict.CANNOT_CHECKOUT;

	const isBulkItem = item.kind === ('BULK' as ItemKind.BULK);
	if (isBulkItem) {
		if (item?.availability?.status === 'RETIRED') return ItemConflict.RETIRED;

		const bookedQuantity = Number(item?.bookedQuantity);
		const totalItemQuantity = Number(item?.quantity);
		const availableQuantity = Number(item?.availability?.quantity);

		// If item availability for checkout is requested, then reservation is being converted to a checkout
		// and we need to check if the bulk instance index is available for checkout
		if (item?.totalCheckedOutQuantity) {
			const quantityAvailableForCheckout = totalItemQuantity - item.totalCheckedOutQuantity;
			if (bookedQuantity > quantityAvailableForCheckout) {
				return ItemConflict.UNAVAILABLE;
			}
		}

		if (totalItemQuantity < 1 || bookedQuantity > totalItemQuantity) {
			return ItemConflict.INSUFFICIENT_QUANTITY;
		}

		if (
			availableQuantity < 1 ||
			bookedQuantity > availableQuantity ||
			item?.availability?.status === 'UNAVAILABLE'
		) {
			return ItemConflict.UNAVAILABLE_QUANTITY;
		}
	}

	if (!isBulkItem && item.availability && item.availability?.status !== 'AVAILABLE') {
		switch (item.availability?.status) {
			case 'ASSIGNED':
				return ItemConflict.ASSIGNED;
			case 'CHECKED_OUT':
				return ItemConflict.CHECKED_OUT;
			case 'RETIRED':
				return ItemConflict.RETIRED;
			case 'FLAGGED':
				return context.isMaintenanceUser ? ItemConflict.AVAILABLE : ItemConflict.FLAGGED;
			case 'RESERVED':
				if (item.isRestricted) return ItemConflict.RESTRICTED;
				return ItemConflict.RESERVED;
			default:
			case 'UNAVAILABLE':
				return ItemConflict.UNAVAILABLE;
		}
	}

	if (item.isRestricted) {
		return ItemConflict.RESTRICTED;
	}

	if (context.reservationLocation && item.locationId !== context.reservationLocation) {
		return ItemConflict.AT_WRONG_LOCATION;
	}

	return ItemConflict.AVAILABLE;
};

export const _getAssignToKitItemConflict = (kitStatus: KitStatus, item: AssignToKitConflictItem): ItemConflict => {
	if (item.kind === ItemKind.BULK) {
		return ItemConflict.UNAVAILABLE_TO_ADD_TO_KIT;
	}

	if (item.availability?.status === 'RETIRED') {
		return ItemConflict.RETIRED;
	}

	if (item.availability?.status === 'ASSIGNED') {
		return ItemConflict.ASSIGNED;
	}

	return ItemConflict.AVAILABLE;
};
