import { DateTime } from '@cheqroom/date-time';
import {
	Archive,
	Barcode,
	Calendar,
	Categories,
	Contacts,
	Deprecation,
	DotsHorizontal,
	FlagAlt,
	Kits,
	Marker,
	Number,
	Qr,
	RoundedSquare,
} from '@cheqroom/icons';
import { FC } from 'react';

import { DateFormatter } from '../../util';
import { ItemFields } from './python.hooks';
import { FieldType, SystemFieldType } from './types';

export const getIconForField = (fieldId: string): FC => {
	if (fieldId.startsWith('fields.')) return DotsHorizontal;

	switch (fieldId) {
		case SystemFieldType.barcode:
			return Barcode;
		case SystemFieldType.qr:
			return Qr;
		case SystemFieldType.purchaseDate:
		case SystemFieldType.warrantyDate:
		case SystemFieldType.created:
		case SystemFieldType.modified:
			return Calendar;
		case SystemFieldType.location:
		case SystemFieldType.address:
			return Marker;
		case SystemFieldType.purchasePrice:
		case SystemFieldType.residualValue:
			return Deprecation;
		case SystemFieldType.flag:
			return FlagAlt;
		case SystemFieldType.contact:
			return Contacts;
		case SystemFieldType.category:
			return Categories;
		case SystemFieldType.kit:
			return Kits;
		case SystemFieldType.expired:
			return Archive;
		case SystemFieldType.brand:
		case SystemFieldType.model:
			return RoundedSquare;
		case SystemFieldType.quantity:
			return Number;
		default:
			return DotsHorizontal;
	}
};

export const getItemFieldValue = (itemFields: ItemFields, fieldId: string) => {
	if (fieldId === SystemFieldType.contact && itemFields.status !== 'available') {
		switch (itemFields.status) {
			case 'checkedout':
				return itemFields.order?.customer?.name;
			case 'in_custody':
				return itemFields.custody?.name;
		}
	}

	if (fieldId === SystemFieldType.flag) {
		return itemFields.flag?.name;
	}

	if (fieldId === SystemFieldType.barcode) {
		return itemFields.barcodes?.[0];
	}

	if (fieldId === SystemFieldType.qr) {
		return itemFields.codes?.[0];
	}

	if (fieldId.startsWith('fields.')) {
		const customFieldKey = fieldId.replace('fields.', '');
		return itemFields.fields[customFieldKey] as unknown | undefined;
	}

	const subObjects = [SystemFieldType.kit, SystemFieldType.category, SystemFieldType.location];
	const fieldType = subObjects.find((type) => fieldId === type || fieldId.startsWith(`${type}.`));
	if (fieldType) {
		if (fieldType === fieldId) {
			const field = itemFields[fieldType] as { name?: string } | undefined;
			if (field) {
				return field?.name;
			}
		} else {
			const subFieldKey = fieldId.replace(`${fieldType}.`, '');
			const subObject = itemFields[fieldType] as { [key: string]: string } | undefined;
			if (subObject && subObject[subFieldKey]) {
				return subObject[subFieldKey];
			}
		}
	}

	if (
		[
			SystemFieldType.created,
			SystemFieldType.purchaseDate,
			SystemFieldType.warrantyDate,
			SystemFieldType.modified,
		].includes(fieldId as SystemFieldType)
	) {
		// Depending on the origin, the date can be an ISO string or a moment object
		const dateValue = itemFields[fieldId] as string | { toISOString: () => string };
		if (!dateValue) return;

		const dateString = typeof dateValue === 'object' ? dateValue.toISOString() : dateValue;
		return DateFormatter.shortDate(DateTime.fromISOString(dateString));
	}

	return itemFields[fieldId] as unknown | undefined;
};

export const mapItemFieldIdToFieldId = (itemFieldId: FieldType) => {
	if (itemFieldId === SystemFieldType.barcode) return 'barcodes';
	if (itemFieldId === SystemFieldType.qr) return 'codes';
	if (itemFieldId === SystemFieldType.kit) return 'kit.name';
	if (itemFieldId === SystemFieldType.category) return 'category.name';
	if (itemFieldId === SystemFieldType.location) return 'location.name';
	return itemFieldId;
};
