import { Analytics, AnalyticsPlugin, Appcues, Beamer, LogRocket, Segment, Vitally } from '@cheqroom/analytics';

const Client = new Analytics(
	[
		process.env.BEAMER_PRODUCT_KEY &&
			new Beamer({
				product_id: process.env.BEAMER_PRODUCT_KEY,
				selector: '#product-changes',
				button: false,
				lazy: true,
				top: 0,
				right: 0,
			}),
		process.env.SEGMENT_KEY &&
			new Segment({ apiHost: process.env.SEGMENT_API as string, apiKey: process.env.SEGMENT_KEY }),
		process.env.APPCUES_ID && new Appcues({ appcuesId: process.env.APPCUES_ID }),
		process.env.LOGROCKET_APP_ID &&
			new LogRocket({ token: process.env.LOGROCKET_APP_ID, release: process.env.BUILD_VERSION }),
	].filter(Boolean) as AnalyticsPlugin[]
);

export default Client;
