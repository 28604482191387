import cr from '@cheqroom/core';
import messg from 'messg';

var sanitizer = cr.common.sanitizeHtml;

var msgHandler = {
	notifications: {},
};

// Shows a normal "success" notification for 4 sec
msgHandler.showNotification = function (msg, closable, timeout = 4000) {
	return msgHandler._showMessage(
		'success',
		"<i class='fa fa-check-circle'></i>&nbsp;" + sanitizer(msg),
		timeout,
		closable == null ? true : closable
	);
};

// Shows a "danger" notification for 10 sec
// When unrecoverable is set, we blockout the screen and force the user to reload
msgHandler.showError = function (msg, unrecoverable, closable, buttons) {
	var recoverable = unrecoverable != null ? !unrecoverable : true;

	return msgHandler._showMessage(
		'error',
		"<i class='fa fa-exclamation-triangle'></i>&nbsp;" + (sanitizer(msg) || 'An unknown error occurred'),
		recoverable ? 10000 : null,
		closable == null ? true : closable,
		unrecoverable,
		undefined,
		buttons
	);
};

msgHandler.showBusy = function () {
	// if only 1 parameter is passed then we except that it is the ID
	// to hide the busy message
	if (arguments.length == 1) {
		msgHandler._hideMessage(arguments[0]);
		return;
	}

	return msgHandler._showMessage(
		'success',
		"<i class='fa fa-circle-o-notch fa-spin'></i>&nbsp;" + arguments[0],
		null,
		true,
		null,
		arguments[1]
	);
};

// Shows a global offline notification which cannot be closed
msgHandler.showOffline = function () {
	return msgHandler._showMessage(
		'error',
		"<i class='fa fa-exclamation-triangle'></i>&nbsp;Error connecting to the Internet",
		null,
		null,
		null,
		'offline'
	);
};

// Hides the global offline notification
msgHandler.hideOffline = function () {
	return msgHandler._hideMessage('offline');
};

// Implementation for showing a message in the UI
msgHandler._showMessage = function (messageType, message, timeout, closable, unrecoverable, uniqueId, buttons) {
	// Generate unique id for each error message so we only show unique errors
	var msgHashCode = 'notify_' + (uniqueId ? uniqueId : message.hashCode()),
		notifications = msgHandler.notifications;

	if (!notifications[msgHashCode]) {
		// Create message for type
		var msg = messg[messageType](message, timeout).hide(function () {
			// Clean up active notifications
			delete notifications[msgHashCode];
		});

		// Keep track of active notifications
		notifications[msgHashCode] = msg;

		if (buttons?.length ?? 0 > 0) {
			buttons.forEach(({ label, callback }) => {
				msg.button(label, callback);
			});
		}

		if (unrecoverable) {
			msg.button('Reload', function () {
				window.location.reload(true);
			});
			msgHandler.hideOffline();

			//BUGFIX blockout not shown on page load
			//(div not rendered on page yet)
			setTimeout(function () {
				var blockout = $('<div class="modal-backdrop fade in"></div>').appendTo($('body'));
			}, 50);

			return;
		}

		// Can message be closed?
		if (closable) {
			msg.button('x');
		} else {
			msg.button('');
		}

		return msg;
	}

	return null;
};

msgHandler._hideMessage = function (id) {
	var msgHashCode = 'notify_' + id,
		notifications = msgHandler.notifications;
	if (notifications[msgHashCode]) {
		notifications[msgHashCode].hide();
	}
};

export default msgHandler;
