import tw, { styled } from 'twin.macro';

import type { Align, Justify, Props, Spacing } from './Stack';

export const getAlignStyles = (align?: Align) => {
	switch (align) {
		case 'baseline':
			return tw`items-baseline`;
		case 'center':
			return tw`items-center`;
		case 'end':
			return tw`items-end`;
		case 'start':
			return tw`items-start`;
		case 'stretch':
			return tw`items-stretch`;
	}
};

export const getJustifyStyles = (justify?: Justify) => {
	switch (justify) {
		case 'around':
			return tw`justify-around`;
		case 'between':
			return tw`justify-between`;
		case 'center':
			return tw`justify-center`;
		case 'end':
			return tw`justify-end`;
		case 'evenly':
			return tw`justify-evenly`;
		case 'start':
			return tw`justify-start`;
	}
};

export const getSpacingStyles = (spacing?: Spacing) => {
	switch (spacing) {
		case 'extraTight':
			return tw`gap-1`;
		case 'tight':
			return tw`gap-2`;
		case 'loose':
			return tw`gap-4`;
		case 'mediumLoose':
			return tw`gap-6`;
		case 'extraLoose':
			return tw`gap-8`;
	}
};

// Aliasing the "wrap" property because it's a reserved DOM prop
interface StyledProps extends Omit<Props, 'wrap'> {
	shouldWrap?: boolean;
}

export const StyledStack = styled.div<StyledProps>(({ inline, align, justify, spacing, vertical, shouldWrap }) => [
	inline ? tw`inline-flex` : tw`flex`,
	getAlignStyles(align),
	getJustifyStyles(justify),
	getSpacingStyles(spacing),
	vertical && tw`flex-col`,
	shouldWrap && tw`flex-wrap`,
]);
