import debounce from 'viewmodels/debounce';
import system from 'durandal/system.js';
import ko from 'knockout';
import kov from 'knockout-validation';

// http://stackoverflow.com/questions/10745486/automatically-trim-whitespace-from-all-observable-values
ko.subscribable.fn.trimmed = function () {
	return ko.computed({
		read: function () {
			if (typeof this() != 'string') return this();

			return this().trim();
		},
		write: function (value) {
			this(typeof value == 'string' ? value.trim() : value);
			this.valueHasMutated();
		},
		owner: this,
	});
};

export default {
	mixin: function (usersync, options) {
		var orig_fromJson = usersync._fromJson,
			orig_reset = usersync.reset,
			global = options.global,
			i18n = options.i18n,
			abortController = null;

		// Core method overrides
		// ----
		usersync._fromJson = function (data, opt) {
			data.reportEmail = data.reportEmail || global.central.group.raw.admin.email;

			// Use apply to call function so we can pass the
			// Location context otherwise it would use Window context
			return orig_fromJson.apply(usersync, [data, opt]).then(function () {
				usersync.cantSave(true);
				usersync.canEdit(true);

				usersync.oId(usersync.id);
				usersync.oEnabled(usersync.enabled);
				usersync.oName(usersync.name || '');
				usersync.oHost(usersync.host || '');
				usersync.oPort(usersync.port || 389);
				usersync.oLogin(usersync.login || '');
				usersync.oPassword(usersync.password || '');
				usersync.oNewUsers(usersync.newUsers || 'ignore');
				usersync.oExistingUsers(usersync.existingUsers || 'ignore');
				usersync.oMissingUsers(usersync.missingUsers || 'ignore');
				usersync.oAutoSync(usersync.autoSync);
				usersync.oOverwriteLocalUsers(usersync.overwriteLocalUsers);
				usersync.oRole(usersync.role || 'self_service');
				usersync.oUserGroups(usersync.contactGroups);
				usersync.oQuery(usersync.query);
				usersync.oBase(usersync.base);
				usersync.oNameField(usersync.nameField);
				usersync.oEmailField(usersync.emailField);
				usersync.oRestrictLocations(usersync.restrictLocations);
				usersync.oTimezone(usersync.timezone);
				usersync.oHostCert(usersync.hostCert || 'ldap_tls_demand');
				usersync.oCACert(usersync.caCert);
				usersync.oReport(usersync.report);
				usersync.oReportTo(usersync.reportEmail);

				return data;
			});
		};

		usersync.reset = function () {
			// Use apply to call function so we can pass the
			// UserSync context otherwise it would use Window context
			orig_reset.apply(usersync);

			// Reset observables to defaults
			usersync.cantSave(true);
			usersync.canEdit(true);

			usersync.oId(usersync.id);
			usersync.oEnabled(usersync.enabled);
			usersync.oName(usersync.name || '');
			usersync.oHost(usersync.host || '');
			usersync.oPort(usersync.port || 389);
			usersync.oLogin(usersync.login || '');
			usersync.oPassword(usersync.password || '');
			usersync.oNewUsers(usersync.newUsers || 'ignore');
			usersync.oExistingUsers(usersync.existingUsers || 'ignore');
			usersync.oMissingUsers(usersync.missingUsers || 'ignore');
			usersync.oAutoSync(usersync.autoSync);
			usersync.oOverwriteLocalUsers(usersync.overwriteLocalUsers);
			usersync.oRole(usersync.role || 'self_service');
			usersync.oUserGroups(usersync.contactGroups);
			usersync.oQuery(usersync.query);
			usersync.oBase(usersync.base);
			usersync.oNameField(usersync.nameField);
			usersync.oEmailField(usersync.emailField);
			usersync.oRestrictLocations(usersync.restrictLocations);
			usersync.oTimezone(usersync.timezone);
			usersync.oHostCert(usersync.hostCert || 'ldap_tls_demand');
			usersync.oCACert(usersync.caCert);
			usersync.oReport(usersync.report);
			usersync.oReportTo(usersync.reportEmail || global.central.group.raw.admin.email);

			var data = usersync.oData();
			data.name.isModified(false);
			data.reportTo.isModified(false);
		};

		// Knockout validation rules
		// ----
		ko.validation.rules['userSyncExists'] = {
			async: true,
			message: 'User sync name is already taken',
			validator: debounce(function (val, params, callback) {
				// When existing usersync is edited, we don't want to check its current name
				if (usersync.oId() != null && usersync.raw != null && usersync.oData().name() == usersync.raw.name) {
					callback(true);
				} else {
					if (usersync.ds) {
						if (abortController) {
							abortController.abort();
						}

						abortController = new AbortController();

						usersync.ds
							.search({ name: val, pk__ne: usersync.oId() }, '_id', null, null, null, null, {
								abortController: abortController,
							})
							.then(function (resp) {
								callback(resp.count == 0);
							})
							.catch(function () {
								callback({ isValid: false, message: 'Something went wrong' });
							});
					}
				}
			}, 250),
		};
		ko.validation.registerExtenders();

		// Observables
		// ----
		usersync.cantSave = ko.observable(false);
		usersync.canEdit = ko.observable(false);

		usersync.oId = ko.observable(usersync.id || '');
		usersync.oEnabled = ko.observable(usersync.enabled);
		usersync.oName = ko.observable(usersync.name || '');
		usersync.oHost = ko.observable(usersync.host || '');
		usersync.oPort = ko.observable(usersync.port || 389);
		usersync.oLogin = ko.observable(usersync.login || '');
		usersync.oPassword = ko.observable(usersync.password || '');
		usersync.oNewUsers = ko.observable(usersync.newUsers || 'ignore');
		usersync.oExistingUsers = ko.observable(usersync.existingUsers || 'ignore');
		usersync.oMissingUsers = ko.observable(usersync.missingUsers || 'ignore');
		usersync.oAutoSync = ko.observable(usersync.autoSync);
		usersync.oOverwriteLocalUsers = ko.observable(usersync.overwriteLocalUsers);
		usersync.oRole = ko.observable(usersync.role || 'self_service');
		usersync.oUserGroups = ko.observable(usersync.contactGroups);
		usersync.oQuery = ko.observable(usersync.query);
		usersync.oBase = ko.observable(usersync.base);
		usersync.oNameField = ko.observable(usersync.nameField);
		usersync.oEmailField = ko.observable(usersync.emailField);
		usersync.oRestrictLocations = ko.observableArray(usersync.restrictLocations);
		usersync.oTimezone = ko.observable(usersync.timezone);
		usersync.oHostCert = ko.observable(usersync.hostCert || 'ldap_tls_demand');
		usersync.oCACert = ko.observable(usersync.caCert);
		usersync.oReport = ko.observable(usersync.report || 'always');
		usersync.oReportTo = ko.observable(usersync.reportEmail || global.central.group.raw.admin.email);

		usersync.oData = ko.validatedObservable({
			name: usersync.oName.trimmed().extend({ required: true, minLength: 3, userSyncExists: true }),
			reportTo: usersync.oReportTo.extend({
				required: {
					onlyIf: function () {
						return usersync.oReport() != 'never';
					},
				},
				email: true,
			}),
		});

		// Computables
		// ----
		usersync._updateCanSave = debounce(function () {
			var isValid = usersync.isValid() && usersync.oData.isValid(),
				isDirty = usersync.isDirty(),
				restrictLocations = usersync.oRestrictLocations();

			usersync.cantSave(!isValid || !isDirty);
		}, 100);

		usersync._updateName = ko
			.computed(function () {
				usersync.name = usersync.oName();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateHost = ko
			.computed(function () {
				usersync.host = usersync.oHost();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updatePort = ko
			.computed(function () {
				usersync.port = usersync.oPort();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateLogin = ko
			.computed(function () {
				usersync.login = usersync.oLogin();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updatePassword = ko
			.computed(function () {
				usersync.password = usersync.oPassword();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateBase = ko
			.computed(function () {
				usersync.base = usersync.oBase();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateQuery = ko
			.computed(function () {
				usersync.query = usersync.oQuery();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateRole = ko
			.computed(function () {
				usersync.role = usersync.oRole();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateUserGroups = ko
			.computed(function () {
				usersync.contactGroups = usersync.oUserGroups();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateNewUsers = ko
			.computed(function () {
				usersync.newUsers = usersync.oNewUsers();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateExistingUsers = ko
			.computed(function () {
				usersync.existingUsers = usersync.oExistingUsers();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateMissingUsers = ko
			.computed(function () {
				usersync.missingUsers = usersync.oMissingUsers();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateNameField = ko
			.computed(function () {
				usersync.nameField = usersync.oNameField();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateEmailField = ko
			.computed(function () {
				usersync.emailField = usersync.oEmailField();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateTimezone = ko
			.computed(function () {
				usersync.timezone = usersync.oTimezone();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateRestrictLocations = ko.computed(function () {
			var role = usersync.oRole();
			if (usersync.role == 'admin') {
				usersync.oRestrictLocations([]);
			}

			usersync.restrictLocations = usersync.oRestrictLocations();
		});

		usersync._updateAutoSync = ko.computed(function () {
			usersync.autoSync = usersync.oAutoSync();
			usersync._updateCanSave();
		});

		usersync._updateOverwriteLocalUsers = ko.computed(function () {
			usersync.overwriteLocalUsers = usersync.oOverwriteLocalUsers();
			usersync._updateCanSave();
		});

		usersync._updateHostCert = ko
			.computed(function () {
				usersync.hostCert = usersync.oHostCert();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateCACert = ko
			.computed(function () {
				usersync.caCert = usersync.oCACert();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateReport = ko
			.computed(function () {
				usersync.report = usersync.oReport();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });

		usersync._updateReportTo = ko
			.computed(function () {
				var report = usersync.oReport();

				usersync.reportEmail = usersync.oReportTo();
				usersync._updateCanSave();
			})
			.extend({ throttle: 250 });
	},
};
